body {
  overflow-x: hidden;
  font-family: "Assistant", sans-serif;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

.nav-tabs .nav-link.active {
  border-top: 0;
  border-right: 0;
  border-left: 0;
}

.nav-tabs li .active {
  border-bottom: 3px solid #612cbd !important;
}

.table_responsive_data_sub_user th {
  color: #612bbd !important;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  text-align: start !important;
  width: 14.2857%;
}

.black_clr {
  color: #000;
}

.purple_clr {
  color: #612cbd;
}

.bg_purple_clr {
  background-color: white;
}

.input-group-append span {
  height: 100%;
  background: #642fbe;
}

.accountant h2 {
  margin-bottom: 23px;
  color: #000;
  font-weight: 700;
  font-size: 70px;
}

/* ul.MuiPagination-ul.css-wjh20t-MuiPagination-ul li:nth-child(2) button {
  background: #612dbe;
  color: #fff;
} */

ul.MuiPagination-ul.css-wjh20t-MuiPagination-ul li button {
  border-color: #7487e6 !important;
  background-color: #7487e6 !important;
}

li.side-nav-item.menuitem-active {
  background-color: #7487e6 !important;
}

ul.MuiPagination-ul.css-wjh20t-MuiPagination-ul {
  display: flex;
  justify-content: flex-end;
}

.change_password {
  position: relative;
}

.change_password i {
  transform: translate(0px, -50%);
  top: 50%;
  position: absolute;
  right: 10px;
}

.sweet-alert p {
  font-size: 15px;
  font-weight: 600;
}

.sweet-alert div {
  margin: 0px !important;
  padding: 0px !important;
}

.sweet-alert {
  padding: 28px 52px !important;
}

.accountant {
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
}

.signup_login_section {
  height: 100vh;
  margin: 0 auto;
  padding: 0px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
}

.password_info span {
  font-weight: 700;
}

.chat-header {
  background-color: #7487e6 !important;
}

.bg_info_cls:hover {
  background: #612cbd;
}

.add_diff_width {
  width: 100px !important;
  background: #9d9d9d !important;
  border-radius: 10px !important;
  border: 0px !important;
  box-shadow: none !important;
  border: 0px !important;
}

.add_diff_width_color {
  background: #612cbd !important;
  border-radius: 10px !important;
  width: 100px !important;
  border: 0px !important;
}

.bg_info_cls_btn {
  background: #612cbd !important;
  border-radius: 10px !important;
  width: 129px !important;
  border: 0px !important;
}

.bg_info_cls_btn2 {
  background: #9d9d9d !important;
  border-radius: 10px !important;
  border: 0px !important;
  box-shadow: none !important;
  width: 129px !important;
  border: 0px !important;
}

.signup {
  position: relative;
  background-color: #fff;
}

.side-nav .side-nav-link i:hover {
  color: white;
}

@media only screen and (min-width: 200px) and (max-width: 769px) {
  .conatiner-fluid {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

.login_form input,
.form-control,
.login_form select {
  color: #000;
  background: transparent !important;
  border: 0px;
  padding: 6px 10px;
}

.disable_input {
  /* background: #727174 !important;
  color: white !important; */
  padding: 10px 10px !important;
  border-radius: 5px;
}

.form-control {
  color: #000 !important;
  background-color: #e5e3e8 !important;
}

input::placeholder,
.form-control,
.login_form select {
  margin-bottom: 0px;

  font-size: 13px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #a9a9a9;
}

li.side-nav-item.menuitem-active {
  border-bottom: 1px solid #fff;
  border-top: 1px solid #fff;
  /* background: #632ebe !important; */
}

/* body[data-leftbar-theme="dark"] .side-nav .menuitem-active > a {
  color: #000000 !important;
} */

.login_form select {
  border-radius: 5px;
}

.logged_in .form-check-input[type="checkbox"] {
  border: 1px solid black !important;
  padding: 0px;
  border-radius: 0px;
}

.logged_info p {
  font-size: 13px;
}

.logged_in {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.forgot_password p {
  color: #612bbd;
  /* font-family: Assistant; */
  font-size: 13px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.side-nav-item {
  background-color: #faf8ff !important;
  color: #434343 !important;
}

.logged_info .form-check-input {
  width: 0.8em;
  height: 0.8em;
  vertical-align: initial;
}

.btn_info_cls {
  line-height: 14px;
  padding: 0.4rem 0.8rem;
  transition: all 0.3s linear;
  font-weight: 500;
  font-size: 14px;
  background: #7487e6;
  color: #fff;
  border: 2px solid #7487e6;
  border-radius: 5px;
}

.btn-primary {
  background: #7487e6 !important;
  color: #fff !important;
  border: 2px solid #7487e6 !important;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
  background: #7487e6 !important;
  color: #fff !important;
  border: 2px solid #7487e6 !important;
}

.btn-primary:disabled,
.btn-primary.disabled {
  color: #fff !important;
  background-color: #8597f1 !important;
  border-color: #8597f1 !important;
}

button.btn_info_cls:hover {
  color: #7487e6;
  background: transparent;
  border: 2px solid #7487e6;
}

.btn-primary:disabled,
.btn-primary.disabled {
  color: #fff !important;
  background-color: #8597f1 !important;
  border-color: #8597f1 !important;
}

.otp_info {
  display: flex;
  justify-content: center;
  column-gap: 10px;
}

.otp_info input {
  font-weight: 600;
  color: #000;
  background: transparent !important;
  border: 1px solid #612bbd;
  font-size: 20px;
  padding: 6px 11px;
  width: 40px;
}

.sinup_cls p {
  margin-left: 0px;
}

.forgot {
  margin-top: 18px;
  color: #ffd523;
}

p.accunt_info_cls {
  font-size: 13px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
}

.signup_right {
  position: relative;
  text-align: center;
  background-image: linear-gradient(179deg, #612cbd, #ac7cea);
}

.signup_right img {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
}

h5.back_btn a {
  color: #000 !important;
}

.enter_password {
  column-gap: 14px;

  display: flex;
  align-items: center;
}

.password_info {
  border: 1px solid #612bbd;
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

.password_info_1 {
  border-bottom: 1px solid #612cbd;
  width: 21px;
}

h2.title_info {
  color: #000;
  font-weight: 700;
  font-size: 35px;
}

.name_detail {
  display: flex;
  column-gap: 15px;
}

.account_heading img {
  width: 180px;
}

h3.verify_email {
  color: #000;
}

p.digit {
  color: #000;
  font-size: 14px;
  text-align: center;
}

.otp_infoo span {
  flex: 0 0 100%;
  text-align: center;
}

p.inf0_ottp {
  margin-left: 10px;
  position: relative;
  margin-right: 10px;
}

p.inf0_ottp::after {
  position: absolute;
  /* margin-left: 10px; */
  content: "";
  width: 18px;
  height: 3px;
  background: #612cbd;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.welcome_info {
  text-align: center;
  margin: 0 auto;
}

.welcome_page {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.welcome_page h2 {
  font-weight: 400;
  color: #f9f4ff !important;
}

.welcome_page_info {
  height: 80vh;
  /* background-color: #fff; */
}

.welcome_info {
  color: #fff;
  border-radius: 25px;
  /* padding: 100px.; */
  background-image: linear-gradient(183deg, #612cbd, #ac7cea);
  border: 1px solid white;
  /* position: absolute; */
  /* left: 50%; */
  /* top: 50%; */
  /* transform: translate(-50%,-50%); */
}

.table_responsive {
  overflow-x: auto;
  width: 100%;
}

button.starttour_btn {
  transition: all 0.3s linear;
  /* transform: translate(10px, 10px); */
  font-size: 16px;
  font-weight: 700;
  background: transparent;
  border: 1.5px solid #fff;
  /* margin-right: 23px; */
  /* padding: 8px 46px; */
  /* border-radius: 10px; */
}

button.starttour_btn:hover {
  border: 1.5px solid #fff;
  color: #622dbd;
  background: #fff;
}

.dropdown_custom {
  height: 11.8rem;
  overflow-y: scroll;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  transform: translate(0px, 10px);
  z-index: 999999;
  background-color: white;
}

.add_hover_drop:hover {
  background-color: #e5e3e8;
  box-shadow: none;
}

button.starttour_btn:active {
  border: 1.5px solid #fff;
  color: #622dbd;
  background: #fff;
  box-shadow: none;
}

.heading_info {
  margin: 20px auto 0px auto;
}

.logged_info {
  display: flex;
  column-gap: 7px;
}

.otp_infoo {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.otp_infoo input {
  border-radius: 4px;
  background: transparent;
  height: 40px;
  width: 40px !important;
  padding: 0px;
  font-size: 20px;
}

.otp_infoo div {
  display: flex;
  width: 100%;
  justify-content: center;
  column-gap: 11px;
}

.otp_infoo input {
  border: 1px solid #612bbd;
}

.otp_infoo div:focus {
  outline: none;
  border: 0px;
}

.login_form span.text-danger {
  font-size: 13px;
}

.back_btn img {
  width: 5px;
}

.btn_loader .spinner-border {
  width: 1rem !important;
  height: 1rem !important;
  border: 0.2em solid currentColor !important;
}

.spinner-border_form {
  /* border: solid!important; */
  display: flex !important;
  justify-content: center !important;
  height: 90vh !important;
  align-items: center !important;
}

.spinner-border_Verify_form {
  display: flex !important;
  justify-content: center !important;
  height: 100vh !important;
  align-items: center !important;
}

.spinner-border_topBar {
  display: inline-block !important;
  width: 1rem !important;
  height: 1rem !important;
  vertical-align: -0.125em !important;
  border-right-color: transparent !important;
  border-radius: 50% !important;
}

.bg_info_cls {
  background: #612cbd;
}

.profile_info {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  flex: 0 0 25%;
}

.profile_info ul {
  list-style: none;
  padding-left: 0px;
}

.tab_info {
  background: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 12px;
  flex: 0 0 73%;
}

.tab_info form {
  display: flex;
  column-gap: 20px;
}

.tab_info .nav-tab {
  padding-bottom: 0px !important;
  color: #8c8c8c !important;
  background: #f3f3f3;
}

.tab_info .nav-link.active {
  font-weight: 600;
  color: #000;
  background: transparent;
  border-bottom: 2px solid #612cbd;
  border-left: 0px;
  border-top: 0px;
  border-right: 0px;
}

.profile_data {
  flex: 0 0 48%;
}

.content-page {
  background-color: #fff;
}

.tab_info .nav-tabs {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  background: #f3f3f3;
}

/* .logo span.logo-lg {
  background-color: #632ebe;
}

.logo span.logo-lg {
  background-color: #612cbd;
} */

.side-nav {
  /* background: #612cbd; */
}

/* ul.side-nav li a {
  color: #fff !important;
} */

.tab_info .nav-link {
  color: #a7a7a7;
  font-weight: 600;
}

.simplebar-content {
  /* background: #612cbc; */
  background-color: #faf8ff !important;
}

.leftside-menu {
  background: #faf8ff !important;
}

.topbarButtons {
  background-color: #632ebe !important;
  color: white;
}

.searchBtn {
  border-radius: 10px;
}

.guZdik {
  max-width: 100% !important;
  height: 140px !important;
}

.border_browser {
  color: gray !important;
  border: 1px solid gray !important;
  background: transparent !important;
}

.parentCreateOrganisation {
  border: 1px solid black;
  box-shadow: 1px 5px 7px 1px;
}

.dragDropFile {
  border: 1px dotted black !important;
  height: 140px;
}

.content-page {
  background-color: #fff;
}

.tab_info .nav-tabs {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  background: #f3f3f3;
}

/* .logo span.logo-lg {
  background-color: #632ebe;
} */

.logo span.logo-lg {
  /* background-color: #612cbd; */
  background-color: #faf8ff !important;
}

.logo-sm {
  background-color: #faf8ff !important;
}

/* .side-nav {
  background: #612cbd;
} */

ul.side-nav li a {
  /* color: #fff !important; */
  /* color: #434343 !important; */
}

.side-nav-second-level .side-nav-item a span {
  color: #434343;
  font-weight: normal;
}

/* .side-sub-nav-link i {
  color: #434343;
} */

.tab_info .nav-link {
  color: #a7a7a7;
  font-weight: 600;
}

/* .side-nav-item a i {
  color: #434343;
} */

.simplebar-content {
  background: #612cbc;
}

/* .leftside-menu {
  background: #612cbd !important;
} */

.cursorMain {
  cursor: pointer;
}

.heading_subUser {
  color: #632ebe;
  font-size: 23px !important;
}

h3.main_title {
  color: #000;
  font-size: 24px;
}

.page-title-right {
  width: 100%;
  flex: 0 0 100%;
  display: flex;
  justify-content: space-between;
}

.page-title-right form {
  order: 2;
}

h3.usr_name {
  font-weight: 700;
  color: #642fbe;
  font-size: 20px;
  line-height: 25px;
}

.labelSubUser {
  cursor: pointer;
}

.bg_clr {
  background-color: #f3f3f3;
}

.bg_cls_info {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.sweet-alert {
  width: auto !important;
  font-size: 8px;
  font-weight: 500;
  padding: 54px 40px !important;
  border-radius: 10px !important;
}

.sweet-alert a,
.sweet-alert a:hover {
  background: #7487e6 !important;
}

span.account-user-name,
span.account-position {
  color: #000;
}

.profile_info img {
  width: 100%;
}

.passwordLabel {
  font-size: 12px;
}

.modal-content button.bg_info_cls {
  font-size: 12px;
  background: #612cbd !important;
  line-height: 0px;
  padding: 12px 11px;
}

.modal-body {
  padding-top: 0px;
}

.modal-content {
  border-radius: 10px !important;
}

button.btn-close {
  color: #612cbd !important;
  border-radius: 100%;
  font-size: 10px;
  margin-bottom: 2px !important;
  font-weight: 800 !important;
}

.modal-title {
  width: 100%;
  text-align: center;
  margin: 0px;
}

table tr {
  text-align: center;
}

button#nav-profile-tab {
  border-top: 0px !important;
  border-right: 0px !important;
  /* border-bottom: 0px !important; */
  border-left: 0px !important;
  /* border: 0px !important; */
}

button#nav-home-tab {
  border-top: 0px !important;
  border-right: 0px !important;
  /* border-bottom: 0px !important; */
  border-left: 0px !important;
  /* border: 0px !important; */
}

.profile_data .form-control:disabled,
.form-control[readonly] {
  color: #6b6b6b !important;
}

/* i18n css */
.language-selector {
  /* margin: 10px; */
}

.language-select {
  padding: 4px;
  font-size: 16px;
  border: 0px !important;
  border-radius: 8px;
  background-color: transparent;
  color: #333;
  appearance: none;
  cursor: pointer;
  outline: none;
  transition: border-color 0.3s ease;
}

.language-select:focus {
  border-color: #612cbd;
}

.language-select option {
  padding: 10px;
}

/* Custom dropdown arrow */
.language-select::after {
  content: "\25BC";
  /* Unicode character for downward arrow */
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #555;
}

/* i18n css */

.profile_skill_width {
  width: 98%;
}

/* // north api  */
.north_api_comapnyLoader {
  display: flex;
  justify-content: center;
  height: 179px;
  align-items: center;
}

.north_api_comapnyLoader .spinner-border {
  width: 1rem !important;
  height: 1rem !important;
  /* border: 0.2em solid currentColor !important; */
}

.form-control,
.PhoneInputInput {
  color: #000;
  background: #e5e3e8;
  border: 0px;
  padding: 6px 10px;
  border: none !important;
  outline: none !important;
}

.font_size {
  font-size: medium !important;
  color: #000000 !important;
}

/* // north api  */

@media (max-width: 991px) {
  .profile_info {
    flex-wrap: wrap;
    flex: 0 0 100%;
  }

  .welcome_page_info {
    width: 100%;
    overflow: hidden !important;
  }

  .profile_info div {
    flex: 0 0 30% !important;
  }

  .tab_info {
    margin-top: 50px;
    flex: 0 0 100%;
  }

  .profile_info img {
    width: auto;
  }

  .profile_data {
    flex: 0 0 100%;
  }
}

@media (max-width: 479px) {
  button.starttour_btn {
    margin-bottom: 10px;
    padding: 6px 18px !important;
    font-size: 13px;
  }

  table.bg_cls_info.table th {
    padding: 8px 3px;
    font-size: 13px;
  }

  .table tr {
    text-align: center;
    font-size: 13px;
  }

  .welcome_info {
    margin-bottom: 30px;
  }

  p.accunt_info_cls {
    margin-top: 10px !important;
  }

  .btn_info_cls {
    line-height: 12px;
    padding: 6px 10px;
    font-size: 12px;
  }

  .profile_info img {
    width: 100% !important;
  }

  .tab-pane form {
    padding: 0px;
    display: flex;
    justify-content: center;
  }

  .tab-pane .row {
    justify-content: center;
    padding-left: 0px !important;
    padding-right: 0px;
  }
}

.bottom_main {}

@media only screen and (min-width: 320px) and (max-width: 769px) {
  .signup_right {
    display: none !important;
  }

  .parentLeftSidebar {
    height: 300px !important;
  }

  .welcome_page_info {
    height: none !important;
    /* background: #fff !important; */
  }

  @keyframes fadeDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .leftside-menu {
    text-align: center;
    animation: fadeDown 0.2s ease-in-out;
  }

  .add_color_heading {
    font-size: 24px !important;
    line-height: 28px !important;
    font-weight: 600;
    color: rgba(99, 46, 190, 1);
  }

  .side-nav .side-nav-link span {
    font-size: 12px;
  }

  .textLeftSidebar {
    font-size: 12px;
  }

  [data-simplebar] {
    margin-bottom: 0px !important;
  }

  .side-nav {
    margin-bottom: 0% !important;
  }

  /* .lalalalal {
    overflow-y: auto; 
    max-height: 100vh; 
    min-height: 5vh !important;
  } */

  /* .leftside-menu {
    height: 40vh !important;
  } */
}

.annim {
  color: red;
}

.search_box_info .searchBox {
  padding: 0px !important;
  padding-left: 6px !important;
}

.searchWrapper.search-wrapper {
  border: 0px !important;
}

.multiSelectorDropDown {
  background: #e5e3e8 !important;
  border: 0 !important;
}

.searchWrapper {
  border: 0 !important;
}

.descriptionText {
  background: #e5e3e8 !important;
  border: 0px !important;
  outline: none;
  border-radius: 5px;
  height: 80px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.optionContainer li:hover {
  background-color: #e5e3e8 !important;
  color: #000 !important;
}

/* .sidebarButtonSet {
  top: 41rem !important;
  position: inherit !important;
} */
/* 
.media_web,
.media_mob {
  background-color: #ac7cea !important;
} */

.iconsLeftSidebar {
  color: #9d9d9d;
}

/* .textLeftSidebar {
  font-size: 0.9375rem;
} */
.hide_box select.PhoneInputCountrySelect {
  display: none;
}

.activeClassSidebar {
  background-color: #e7e0e0 !important;
  color: #000 !important;
  font-weight: 700;
  width: 230px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: #7487e6 !important;
}

.activeClassSidebar span {
  color: white !important;
}

@media (min-width: 768px) {
  li.side-nav-item.menuitem-active {
    border-bottom: 1px solid #fff;
    border-top: 1px solid #fff;
    font-weight: 700;
    /* width: 230px; */
    /* background: #e7e0e0 !important; */
    background-color: #7487e6 !important;
    border-top-right-radius: 30px;
    /* Adjust the value as needed */
    border-top-right-radius: 30px;
    /* Adjust the value as needed */
    border-bottom-right-radius: 30px;
    color: white !important;
  }
}

li.side-nav-item:hover {
  /* / border-bottom: 1px solid #fff;/ */
  /* / border-top: 1px solid #fff;/ */
  font-weight: 700;
  color: black !important;
  /* width: 230px; */
  /* / background: #e7e0e0 !important;/ */
  /* background-color: #9ca8e3 !important; */
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.logoutModal {
  width: 255px;
}

.optionContainer li {
  background: transparent !important;
  color: #000 !important;
}

.parentLeftSidebar {
  display: flex;
  flex-direction: column;
  height: 90%;
  background-color: #faf8ff;
  /* Ensure the parent container fills the height of its parent */
}

@media (min-width: 768px) {

  /* Adjust the breakpoint according to your mobile view */
  .media_mob {
    flex-grow: 1;
    /* Allow the first div to grow and take available space */
  }
}

.media_mob {
  background-color: #faf8ff;
}

.bottom-sidebar {
  align-self: flex-end;
  /* Align the second div to the bottom of the parent container */
}

.textLeftSidebar span.px-2.sidebar_listItems {
  color: #8391a2;
}

li.sidebar_listItems:hover {
  font-weight: 700;
  color: black !important;
  /* width: 230px; */
  background-color: #f4f0ff !important;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.menuitem-active a span {
  color: white;
}

.menuitem-active a:hover {
  color: black !important;
}

/* .menuitem-active a i {
  color: white;
} */

.menuitem-active .side-sub-nav-link i {
  color: white;
}

@media screen and (max-width: 768px) and (min-width: 300px) {
  .formTopBarMobileView {
    display: flex;
  }

  .add_scrool_mobile {
    overflow-x: scroll;
  }
}

.profileTopBar {
  padding-right: 24px;
}

.btn_inf_cls_cancel {
  padding: 6px 10px;
  font-size: 12px;
  line-height: 14px;
  padding: 0.4rem 0.8rem;
  transition: all 0.3s linear;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  border-radius: 5px;
}

.form-select {
  background-image: none !important;
}

.EartIcon {
  background-image: url("/src/assets/images/flags/globe.png") !important;

  background-size: 20px !important;
  /* Set the width */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.graph_info table tbody tr:nth-child(even) {
  background: #f4f0ff;
}

.graph_info table tr th {
  font-size: 14px;
}

.graph_info table tr td {
  font-size: 15px;
}

.dropdowm {
  background: #faf8ff;
  border: 2px solid #d3d3d3;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  width: 9.1rem !important;
}

.add_border_bottom {
  border-bottom: 2px solid #d3d3d3;
}

.add_border_bottom:hover {
  background-color: lightgray !important;
}

.dropdowm:not(:nth-child(3)) {
  border-bottom: 0px;
}

.add_position {
  width: 19rem;
  transform: translate(-20px, 56px);
}

.dropdownBg {
  background-color: lightgray !important;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 400px;
  /* Fixed height for the dropdown menu */
  overflow-y: auto;
  /* Enable scrolling if the content exceeds the height */
  border: 1px solid #ccc;
  background: #fff;
  z-index: 1000;
  height: 400px;
}

.backgroundColorForTables {
  background-color: #f4f0ff;
  border-radius: 5px;
}

.borderBottomColorForTables {
  border-bottom: 1px solid #e3d9ff !important;
}

.ccEFvh {
  background-color: #632ebe !important;
}

.kklZaP {
  background-color: #f0e8ff !important;
  color: #000 !important;
  font-size: 12px !important;
}

.dXrRVT {
  display: none !important;
}

.hzuvgG {
  margin-right: 30px !important;
}

.eGibQe {
  box-shadow: none !important;
  border: 1px solid #632ebe;
}

.hzNPjE {
  border-top: 1px solid #632ebe !important;
}

/* .btn_loader{
  display: flex;
  al
} */

.kaHhsh {
  height: calc(75vh - 112px) !important;
}

@media only screen and (min-width: 768px) and (max-width: 3100px) {
  .chatbot-container {
    position: fixed;
    bottom: 50px;
    top: 10vh;
    right: 15px;
    z-index: 999;
  }

  .bibEQi {
    width: 20vw !important;
    box-shadow: none !important;
    border: 1px solid #632ebe !important;
    height: 75vh !important;
  }
}

/* @media only screen and (min-width: 375px) and (max-width: 667px) {
  .login_form {
    height: 539px;

  }
} */

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .chatbot-container {
    width: 100% !important;
  }

  .heading_subUser {
    /* color: #632ebe; */
    font-size: 15px !important;
    /* line-height: 16px; */
  }

  .table tr {
    /* text-align: center; */
    font-size: 13px;
    white-space: nowrap;
  }

  .add_width_mobile_view {
    width: auto;
    color: #7e7e7e;
    font-size: 10px;
    margin-right: 2px;
    padding: 5px;
  }

  .add_custom_css {
    max-width: 400px;
    position: absolute !important;
    transform: translate(0px, 125px) !important;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .settingsTabs {
    font-size: 11px !important;
    padding: 4px !important;
  }

  .graph_info {
    overflow-x: auto;
  }
}

.active_link_mobile {
  background-color: #612bbd;
  color: white !important;
}

.dropdowm {
  background: #faf8ff;
  border: 2px solid #d3d3d3;
  border-radius: 6px;
  overflow: hidden;
  width: 9.1rem !important;
}

.settingsTabs {
  font-size: 16px;
}

.add_border_bottom {
  border-bottom: 2px solid #d3d3d3;
}

.dropdowm:not(:nth-child(3)) {
  border-bottom: 0px;
}

.heading_Dashboard {
  color: #632ebe;
  font-size: large;
  font-weight: 700;
}

.striped-table tbody tr:nth-child(even) {
  background-color: #f4f0ff;
  /* Set the background color for odd rows */
}

.striped-table {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.striped-table-header {
  background-color: #f4f0ff;
  /* Set the background color for odd rows */
}

.floatRight {
  float: right;
}

.heading {
  color: #612bbd;
  font-size: 23px;
}

.search_bar {
  background-color: #eeeeee;
}

.table_striped {
  background-color: #f4f0ff !important;
}

.search_icon {
  position: absolute;
  left: 6%;
  top: 24%;
  z-index: 11;
}

.searchbar_div {
  position: relative;
}

.table_header {
  width: 53%;
}

.cp {
  cursor: pointer;
}

span.color_info_cls {
  /* border: 1px solid #000; */
  width: 20px !important;
  display: inline-block;
  border-radius: 50%;
  height: 20px;
}

/* google translator ------------------------- */
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none;
}

.goog-te-gadget {
  color: #fff !important;
}

div#google_translate_element span {
  display: none;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
  display: none;
}

div#goog-gt-tt {
  display: none !important;
}

.skiptranslate.goog-te-gadget {
  position: relative;
}

select.goog-te-combo {
  border: 0;
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
  left: 0;
}

select.goog-te-combo:focus {
  outline: none;
}

.goog-te-gadget .goog-te-combo {
  margin: 0px 0px;
}

li.multi_language {
  display: flex;
  width: 150px;
}

/* end ------------------ */
.quarterButton {
  margin: 5px !important;
  width: 75px !important;
  background-color: #632ebe !important;
  box-shadow: none !important;
}

.SubmitBtn {
  /* width: 5px !important; */
}

.calendarPosition {
  align-items: center !important;
}

.redHighlightTopbackground {
  background: #fef1f1;
  color: #ff0202;
  margin-left: 1px;
  margin-right: 1px;
}

.flag_info select.goog-te-combo {
  margin: 0px 0px !important;
  left: 0;
}

.flag_info img {
  width: 20px !important;
}

.table_icons {
  width: 84px;
  display: flex;
  padding: 6px;
  line-height: 18px;
  border-radius: 7px;
  align-items: center;
  justify-content: start;
  white-space: nowrap;
}

.inputFields_table {
  width: auto;
  background: #f4f0ff !important;
}

body.authentication-bg {
  top: 0px !important;
}

.spinner-border_form1 {
  /* border: solid!important; */
  display: flex !important;
  justify-content: center !important;
  height: 67vh !important;
  align-items: center !important;
}

/* .auto_width {
  width: auto;
} */
.icon_set {
  width: 23px;
  height: 23px;
  border-radius: 5px;
  padding: 1px;
  background: #612cbd !important;
}

.customColorbtb {
  color: #632ebe !important;
  margin: 20px auto !important;
  font-weight: 700 !important;
  position: relative;
}

.custom-calendar {
  font-family: Arial, sans-serif;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-calendar .rbc-toolbar {
  background-color: #f0f0f0;
  border-bottom: 1px solid #ccc;
}

.custom-calendar .rbc-btn-group {
  margin-bottom: 10px;
}

.custom-calendar .rbc-btn {
  color: #333;
  background-color: transparent;
  border: none;
  font-size: 14px;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
}

.custom-calendar .rbc-btn:hover {
  background-color: #eaeaea;
}

.custom-calendar .rbc-toolbar-label {
  font-size: 16px;
  font-weight: bold;
}

.custom-calendar .rbc-month-header {
  background-color: #f0f0f0;
  border-bottom: 1px solid #ccc;
}

.custom-calendar .rbc-row-content {
  border-top: 1px solid #ccc;
}

.custom-calendar .rbc-day-bg {
  background-color: #fff;
}

.custom-calendar .rbc-header {
  font-size: 12px;
  font-weight: bold;
  color: #666;
}

.custom-calendar .rbc-header+.rbc-header {
  border-left: 1px solid #ccc;
}

.custom-calendar .rbc-day-bg+.rbc-day-bg {
  border-top: 1px solid #ccc;
}

.custom-calendar .rbc-today {
  background-color: #ffffe0;
}

.custom-calendar .rbc-off-range {
  color: #ccc;
}

.react-calendar__navigation {
  border-bottom: 1px solid #898989;
}

.add_custom_css {
  max-width: 400px;
  position: absolute;
  transform: translate(45px, 29px);
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
}

.react-calendar {
  width: 300px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  border: 0px !important;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  font-size: 35px;
  color: #612bbd;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  font-size: 35px;
  color: #612bbd;
}

span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  color: #632ebe;
  font-size: 16px;
  font-weight: 700;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 1em 0.5em !important;
}

.react-calendar__tile--hasActive {
  background: #f4f0ff !important;
  color: #632ebe !important;
  font-weight: 700 !important;
}

.react-calendar__tile--now {
  background: transparent !important;
}

button.react-calendar__tile.react-calendar__decade-view__years__year {
  color: #828282;
}

.topbarButtons {
  padding: 2px 5px !important;
}

.add_hover_effect:hover {
  background: #ddd7d7;
  padding: 3px;
  border-radius: 8px;
  color: #632ebe !important;
}

/* .add_white_space {
  white-space: nowrap;
} */

.sf-footer {
  line-height: 0px;
  color: #434343 !important;
}

.hovered {
  border: 1px solid #d6cfdb;
  /* Define your hover border style */
}

.belgium_flgg {
  position: relative;
}

.belgium_flgg img {
  position: absolute;
  width: 23px;
  top: 50%;
  left: 7px;
  transform: translate(0, -50%);
}

.belgium_number {
  position: absolute;
  font-size: 13px;
  left: 40px;
  top: 50%;
  transform: translate(0px, -50%);
}

/* 
.belgium_flgg input {
  padding-left: 62px !important;
} */

.belgium_flgg input {
  line-height: 27px !important;
  height: 39px;
}

.accordion-header {
  border: 0px;
  background-color: #632ebe;
  margin-bottom: 15px;
  color: white !important;
}

.accordion-button:not(.collapsed) {
  background-color: #632ebe !important;
}

.accordion-item {
  border: 0px;
}

.mdi-chevron-down.accordio::before {
  font-size: 30px;
  content: "\f0140";
  color: white;
  position: absolute;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.accordion-button:not(.collapsed)::after {
  display: none;
}

i.bi.bi-chat-dots.chat_pot {
  background: #632ebe;
  border-radius: 50px;
  width: 62px;
  height: 62px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
}

.myClass {
  position: absolute;
  right: 50px;
  bottom: 0;
}

.accordion-button::after {
  display: none;
}

.bottom_portion {
  background: #632ebe;
  color: white;
  font-size: 25px;
}

.disabled {
  opacity: 0.5;
  /* Reduced opacity for disabled icon */
  cursor: not-allowed;
  /* Change cursor to not-allowed for disabled icon */
}

.setColor {
  color: #000000 !important;
}

.quarter-cell {
  width: 11rem;
}

.add_border_color {
  width: 104px;
  border: 1.5px solid #612bbd !important;
  border-radius: 5px !important;
  height: 27px;
}

.table_responsive th {
  color: #612bbd !important;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  text-align: start !important;
  width: 14.2857%;
}

.table_responsive td {
  width: 14.2857;
  text-align: start !important;
}

.table_responsive_dashboard th {
  color: #612bbd !important;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  text-align: start !important;
  width: 11.111%;
}

.table_responsive td {
  width: 11.111%;
  text-align: start !important;
}

.table_responsive_middle th {
  color: #612bbd !important;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  text-align: start !important;
  width: 16.666%;
}

.table_responsive_middle td {
  width: 16.666% !important;
  text-align: start !important;
}

/* 
.change_font_color td:nth-child {
  color: #612bbd !important;
} */
.css-lqq3n7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #612bbd !important;
  color: #fff;
}

.css-1y7coo4-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #612bbd !important;
  color: #fff !important;
}

.whitSpace {
  white-space: nowrap !important;
}

/* signup css  */
.add_font_heading {
  font-size: 25px;
  line-height: 28px;
  color: white;
}

.add_second_font {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.add_font_para {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.full-height-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.add_color_heading {
  font-size: 30px;
  line-height: 29px;
  font-weight: 600;
  color: rgba(99, 46, 190, 1);
}

.add_color_para {
  color: rgba(0, 0, 0, 1);
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
}

img.icon_img {
  width: 25.6px;
  height: auto;
}

.add_bg_border {
  background-color: white !important;
  border: 0.8px solid #e9e0e0 !important;
  border-radius: 9.6px !important;
}

.add_border_radius {
  border-radius: 10px !important;
}

button:focus:not(:focus-visible) {
  box-shadow: none !important;
}

.font_define_label {
  font-size: 12px;
  /* line-height: 19px; */
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}

.add_shadow_box {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.add_bg_color {
  background-color: transparent;
  color: black;
  border-bottom: 2px solid rgba(97, 43, 189, 1);
}

.inactive_tab {
  background-color: rgba(243, 243, 243, 1);
  color: gray;
}

.add_box_shadow {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  overflow: hidden;
  border-radius: 10px;
}

.inactive_tab {
  background-color: rgba(243, 243, 243, 1);
  color: gray;
}

.add_font_tab {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.add_bg_color {
  background-color: transparent;
  color: black;
  border-bottom: 2px solid rgba(97, 43, 189, 1);
}

.custonColor {
  color: #000000 !important;
}

.backgroundColorForTables {
  background-color: #f4f0ff;
  border-radius: 5px;
}

.backgroundColorForTableschange {
  background-color: rgb(243, 243, 243);
  border-radius: 5px;
}

.add_top_border_radius {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.add_margin_buttom_box {
  margin-bottom: 3px;
}

.avail-slot-hover:hover {
  background-color: #e1d6fe !important;
}

.table_data_search thead th {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

/* .table_data_search table {
  width: 100%;
  border-collapse: collapse;
}

.table_data_search tbody {
  display: block;
  max-height: 60vh;
  overflow-y: auto;
  height: 60vh;
  width: 100%;
  border-top: 0px !important;

}

.table_data_search thead, tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table_data_search thead tr th {
  border: 0px !important;
}

.table_data_search tbody::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.table_data_search{
  height: 65vh;
} */

/* For Chrome, Safari, Edge, and Opera */
.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
.no-arrows {
  -moz-appearance: textfield;
}

.tabdata {
  text-align: center;
  padding: 30px;
}

.tabs {
  display: flex;
  justify-content: start;
  border-bottom: 1px solid #a9a9a9;
}

.tab {
  /* flex: 1; */
  padding: 15px;
  /* cursor: pointer; */
  width: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0px;
  padding-bottom: 10px;
}

.tab.active {
  border-bottom: 3px solid #632ebe;
  margin-bottom: -2px;
  color: #000000;
  /* background-color: #333; */
}

.contentdata {
  height: 15rem;
  overflow-y: scroll;
}

.tab-content {
  display: block;
}

.contentdata::-webkit-scrollbar {
  display: none;
}

input#mui-2 {
  background: transparent !important;
  font-size: 13px;
  font-weight: 400;
  color: #000;
}

input#mui-1 {
  background: transparent !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #000 !important;
}

fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 0px !important;
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 0px !important;
}

select.form-control.another_input {
  border-radius: 9.6px !important;
}

.add_class_selet {
  border-radius: 9.6px !important;
  /* height: 2.65rem !important; */
  cursor: pointer !important;
  border: 0.8px solid #e9e0e0 !important;
  /* overflow: hidden; */
  padding-top: 2px;
  margin: 0px !important;
  padding-bottom: 2px;
}

.css-13cymwt-control {
  border: none !important;
  /* border-left: 0.8px solid #e9e0e0 !important; */
  /* border-right:0.8px solid #e9e0e0 !important; */
  border-radius: 9.6px !important;
  /* height: 2rem !important; */
  cursor: pointer !important;
}

.css-15lsz6c-indicatorContainer {
  padding: 0px !important;
}

.css-5a01tc-MuiFormControl-root {
  width: 25% !important;
}

.css-oxf2nd {
  width: 25% !important;
}

.MuiFormControl-root.remove_border.pe-0.css-oxf2nd {
  width: 25% !important;
}

.css-16xfy0z-control {
  border: 0px !important;
  background-color: transparent !important;
}

span.css-894a34-indicatorSeparator {
  display: none !important;
}

.select_control.select_control--is-focused.select_control--menu-is-open.css-t3ipsp-control {
  border-radius: 80px !important;
  background: transparent !important;
}

.custom-select.css-b62m3t-container.css-13cymwt-control {
  border: 0px !important;
}

.css-t3ipsp-control:hover {
  border: 0px !important;
  border-color: transparent !important;
  box-shadow: none !important;
  border-radius: 40px !important;
}

.css-t3ipsp-control {
  border-color: transparent !important;
  box-shadow: none !important;
  border-radius: 40px !important;
}

.select__multi-value {
  display: inline-flex;
  margin-right: 8px;
  flex-shrink: 0;
  /* Prevent shrinking of selected items */
}

.select__value-container.select__value-container--is-multi.select__value-container--has-value.css-1dyz3mf {
  /* display: flex; */
  /* flex-wrap: nowrap; */
  overflow-x: auto;
  /* Enable horizontal scrolling */
  width: 100%;
  /* Take full width of the parent container */
  max-width: 100%;
  /* Prevent the container from exceeding parent width */
  white-space: nowrap;
  /* Ensure items don’t wrap to the next line */
}

.select__value-container.select__value-container--is-multi.select__value-container--has-value.css-1dyz3mf::-webkit-scrollbar {
  height: 8px;
}

.select__value-container.select__value-container--is-multi.select__value-container--has-value.css-1dyz3mf::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.select__value-container.select__value-container--is-multi.select__value-container--has-value.css-1dyz3mf::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.select__value-container.select__value-container--is-multi.select__value-container--has-value.css-1dyz3mf::-webkit-scrollbar {
  display: none;
  /* WebKit browsers: hide scrollbar */
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #007bff;
  /* Change this color as needed */
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

fieldset.MuiOutlinedInput-notchedOutline.css-igs3ac {
  border: 0px !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.introjs-nextbutton,
.introjs-prevbutton,
.introjs-skipbutton {
  background-color: #7487e6 !important;
  color: white !important;
  text-shadow: none !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.introjs-skipbutton {
  border: 1px solid #bdbdbd;
  text-decoration: none;
  font-size: 14px !important;
  border-radius: 0.2em !important;
  margin: 0.5rem !important;
  width: 4rem !important;
  height: 3em !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
}

.css-1xc3v61-indicatorContainer {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.css-hlgwow {
  padding-right: 0px !important;
}

/* .css-19bb58m{
  display: flex !important;
  justify-content: center !important;
} */
.MuiOutlinedInput-input {
  padding-left: 5px !important;
}

.add_width_extra th {
  width: 10% !important;
}

.add_style_icon {
  white-space: nowrap;
  background: #7487e6 !important;
  height: 37px;
  width: 37px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* For Webkit-based browsers (Chrome, Safari, etc.) */
.invisible-scroll {
  overflow-y: scroll;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
}

.invisible-scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.PhoneInputCountry {
  display: flex;
  align-items: center;
  justify-content: start;
}

.PhoneInput {
  display: flex;
  align-items: center;
}

.PhoneInputCountryIcon {
  width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
  height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountry {
  padding-left: 10px !important;
}

.css-1dimb5e-singleValue {
  margin-left: -3px !important;
}

.css-9jq23d {
  font-size: 15px !important;
  font-weight: 500 !important;
}

.PhoneInputCountrySelect option {
  color: black !important;
  background-color: white !important;
}

.info-color:hover {
  font-weight: 700;
  color: black !important;
}

.textLeftSidebar span.px-2.sidebar_listItems:hover {
  color: black !important;
}

.table_data_search th {
  color: #4e22a3 !important;
  /* border-bottom: 1px solid #4e22a3 !important; */
}

.table_data_search td {
  color: #534d4d !important;
  font-weight: 500;
}

.css-10wo9uf-option {
  color: #534d4d !important;
  font-weight: 500;
}

.css-d7l1ni-option {
  color: black !important;
}
